import { FlexBox, theme, TextField } from '@rario/shared-components'
import useCurrencyConversion from 'hooks/useCurrencyConversion'
import { PriceProps } from 'interfaces/nftDetail'
import { convertToInternationalCurrencySystem } from 'utils/utils'

const Price: React.FunctionComponent<PriceProps> = ({ priceTitle, minSalePrice }) => {
  // const price = sale_price ? convertToInternationalCurrencySystem(sale_price) : null
  // const minSalePrice = min_sale_price ? convertToInternationalCurrencySystem(min_sale_price) : null
  // const displayPrice = is_pick ? sale_price : minSalePrice || price

  const [, currency, convertedMinSalePrice] = useCurrencyConversion(minSalePrice || 0)

  return (
    <FlexBox width="100%" flexDirection={'column'}>
      <FlexBox alignItems="baseline">
        <TextField
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          fontWeight={600}
          fontSize={'14px'}
          lineHeight={'22px'}
        >
          {currency}
        </TextField>
        <TextField
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          fontWeight={600}
          fontSize={'20px'}
          lineHeight={'28px'}
        >
          {convertToInternationalCurrencySystem(Number(convertedMinSalePrice))}
        </TextField>
      </FlexBox>

      {priceTitle && (
        <TextField
          fontSize={'10px'}
          fontWeight={400}
          lineHeight={'10px'}
          color={theme.colors.whites[7]}
          textTransform="uppercase"
        >
          {priceTitle}
        </TextField>
      )}
    </FlexBox>
  )
}

export default Price
