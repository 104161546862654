import { customStyled, FlexBox, TextField, ThemeType } from '@rario/shared-components'
import { getCDNUrl } from 'utils/utils'

export const CarouselWrapper = customStyled(FlexBox)<{ theme?: ThemeType }>`
        @media(max-width: ${({ theme }) => theme.breakpoints[1]}){
                & .carousel-custom-btn {
                        display: none;
                }
        }
`

export const PicksHeadingWrapper = customStyled(TextField)<{ theme?: ThemeType }>`
        font-family: ${({ theme }) => theme.fonts.primary};
    letter-spacing: ${({ theme }) => theme.letterSpacings.primary};
        font-weight: 600;
        font-size: 12px !important;
        line-height: 14px !important;
        text-transform: capitalize;
`

export const PicksHeadingTextWrapper = customStyled(TextField)<{ theme?: ThemeType }>`
        font-family: ${({ theme }) => theme.fonts.primary};
    letter-spacing: ${({ theme }) => theme.letterSpacings.primary};
        font-weight: 600;
        font-size: 12px !important;
        line-height: 14px !important;
        text-transform: uppercase;
`

export const TimerHeadingWrapper = customStyled(FlexBox)<{
  theme?: ThemeType
  background?: string
  top?: string
}>`
        position: absolute;
        left: 0px;
        top: ${({ top = false }) => top || '50%'};
        height: 1px;
        width: 100%;
        background: ${({ background = false }) =>
          background ||
          'linear-gradient(90deg, rgba(205, 65, 58, 0) 0%, #CD413A 15.63%, #CD413A 80.73%, rgba(205, 65, 58, 0) 100%)'};
`

export const ViewAllCardWrapper = customStyled(FlexBox)<{
  theme?: ThemeType
  cardType?: string
}>`
        position: relative;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        align-items: center;
        margin-bottom: 0px;
        @media (max-width: 768px) {
                margin-bottom: 30px;
                overflow: hidden;
        }
        background: linear-gradient(225deg, white 18px, transparent 20px),
        ${`url(${getCDNUrl('/App/images/home/view-all-card-bg.png')})`};
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        box-shadow: inset 20.1667px -20.1667px 20.1667px rgba(13, 17, 21, 0.1);
        backdrop-filter: blur(10px);
        mask-image: linear-gradient(225deg, transparent 20px, white 20px);
        border-radius: 5px;
        border: 0.5px solid ${({ theme }) => theme.colors.whites[4]};
        @media (max-width: 380px) {
                width: 155px;
        }
`

export const ViewAllMatchWrapper = customStyled(FlexBox)<{
  theme?: ThemeType
}>`
        position: relative;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        align-items: center;
         margin-bottom: 30px;
        @media (max-width: 768px) {
                overflow: hidden;
        }
        background: ${`url(${getCDNUrl('/App/images/home/view-all-card-bg.png')})`};
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        box-shadow: inset 20.1667px -20.1667px 20.1667px rgba(13, 17, 21, 0.1);
        backdrop-filter: blur(10px);
        border-radius: 5px;
        border: 0.5px solid ${({ theme }) => theme.colors.whites[4]};
        @media (max-width: 380px) {
                width: 155px;
        }
`

export const ViewAllPackWrapper = customStyled(FlexBox)<{
  theme?: ThemeType
}>`
              position: relative;
              display: flex;
              flex-direction: column;
              cursor: pointer;
              align-items: center;
              margin-bottom: 0px;
              @media (max-width: 768px) {
                      margin-bottom: 30px;
                      overflow: hidden;
              }
              background: linear-gradient(225deg, white 10px, transparent 12px),
              ${`url(${getCDNUrl('/App/images/home/view-all-card-bg.png')})`};
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              box-shadow: inset 20.1667px -20.1667px 20.1667px rgba(13, 17, 21, 0.1);
              backdrop-filter: blur(10px);
              mask-image: linear-gradient(225deg, transparent 12px, white 12px);
              border-radius: 5px;
              border: 0.5px solid ${({ theme }) => theme.colors.whites[4]};
              @media (max-width: 380px) {
                      width: 155px;
              }
      `
export const TimerIndicator = customStyled(FlexBox)<{
  theme?: ThemeType
  timerColor?: string
}>`
        width: 8px;
        height: 8px;
        margin: 7px 5px 0px 7px;
        border-radius: 50%;
        box-shadow: 0px 0px 1px 1px ${({ theme }) => theme.colors.whites[2]};
        animation: pulseAnimation 1s infinite;
        padding: 0;
        background:  ${({ timerColor }) => timerColor};

        @keyframes pulseAnimation {
                0% {
                   box-shadow: 0 0 0 0px ${({ timerColor }) => timerColor};
                }
                100% {
                   box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.richBlackLight};
                } 
        }
`
