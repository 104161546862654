import { NFTCardWrapper, NFTTitleContainer, NFTSubtitle, NFTCardBackground } from './NFTCard.styles'
import { FlexBox, TextField, theme, trackEvent, Image } from '@rario/shared-components'
import Price from './Price'
import Link from 'next/link'
import { D3CatBoostEnum, NFTTagProps, TabEnum } from 'interfaces'
import NFTDetailSingleImage from 'components/NFTDetail/NFTDetailSingleImage'
import { getCDNUrl, getCurrentYear } from 'utils/utils'
import NFTCardTag from './NFTCardTag'
import { cardScarcityBackground, NftCardProps } from 'interfaces/nftDetail'
import NFTCardRoleTag from 'components/VaultNFTCard/NFTCardRoleTag'
import usePageControl from 'hooks/usePageControl'
import { appendHomeAsReferer } from 'utils/urlUtils'

const NFTCard: React.FunctionComponent<NftCardProps> = ({
  hit,
  activeIndexTitle = TabEnum.CARDS,
  appendRefererInLink = false,
}) => {
  const {
    name,
    asset_id,
    attributes,
    min_sale_price,
    image,
    batting_position,
    bowling_position,
    sub_type,
    is_star_player,
  } = hit
  const { team, league, player, tournament, role, scarcity, year } = attributes
  const subtitle = `${team}, ${league} ${tournament}`

  const trackNFTCard = () => {
    const cardEventData = {
      card_title: name,
      min_sale_price,
      player_name: player,
      link_url: `/listings/${asset_id}`,
      tab_selected: activeIndexTitle,
      card_type: activeIndexTitle,
      click_action: 'click_card_marketplace',
      sub_category: 'listings-card_widget',
      category: 'listings',
    }
    trackEvent('Section Interacted', 'click', cardEventData)
  }

  const { isHomeLandingPage } = usePageControl()

  const tagProps: NFTTagProps[] = [{ role, batting_position, bowling_position }]

  if (Number(year) === getCurrentYear() && scarcity.toUpperCase() !== D3CatBoostEnum.BLUE) {
    tagProps.push({ tagText: 'NEW' })
  }

  const listingUrl = appendHomeAsReferer(`/listings/${asset_id}`, appendRefererInLink)

  return (
    <FlexBox flexDirection={'column'}>
      <NFTCardRoleTag tagProps={tagProps} />

      <NFTCardBackground>
        <NFTCardWrapper
          onClick={() => {
            trackNFTCard()
          }}
          pb={'10px'}
          px={'5px'}
          isHomeLandingPage={isHomeLandingPage}
        >
          <Link href={listingUrl} passHref>
            <a>
              {is_star_player && <NFTCardTag cardTag={sub_type} scarcity={scarcity} />}
              <NFTCardBackground
                width={['95%', null, null, '150px']}
                margin={'auto'}
                borderRadius={'8px'}
                mt={'7px'}
                mx={'auto'}
                height={'auto'}
                position={'relative'}
              >
                <FlexBox position={'absolute'} left="-10px">
                  <Image
                    width={isHomeLandingPage ? '165px' : '160px'}
                    height={'100%'}
                    src={
                      is_star_player
                        ? `${cardScarcityBackground[scarcity]}`
                        : `${getCDNUrl('/App/images/user-profile/vault-pack-bg-v2.png')}`
                    }
                  ></Image>
                </FlexBox>
                <FlexBox
                  position="relative"
                  width={'100%'}
                  flexDirection="column"
                  onClick={() => {
                    window?.ReactNativeWebView?.postMessage('custom_click')
                  }}
                >
                  <NFTDetailSingleImage
                    imageUrl={image}
                    height={'176px'}
                    width={'110px'}
                    margin={'auto'}
                    cursor="pointer"
                    alt={player}
                    scarcity={scarcity}
                    is_star_player={is_star_player}
                  />
                </FlexBox>
              </NFTCardBackground>
              <FlexBox
                width={'100%'}
                flexDirection="column"
                ml={'5px'}
                justifySelf="center"
                pt={12}
              >
                <NFTTitleContainer>
                  <TextField
                    fontSize={'18px'}
                    lineHeight={'22px'}
                    fontFamily={theme.fonts.primary}
                    letterSpacing={theme.letterSpacings.primary}
                    fontWeight={theme.fontWeights[5]}
                    pb={0}
                    as="span"
                    content={player}
                  />
                </NFTTitleContainer>
                <FlexBox flexDirection="column" mt={'5px'}>
                  <NFTTitleContainer>
                    <NFTSubtitle
                      fontSize="12px"
                      lineHeight="16px"
                      fontWeight={400}
                      color={theme.colors.whites[6]}
                      as="span"
                    >
                      {subtitle}
                    </NFTSubtitle>
                    <FlexBox
                      my={'5px'}
                      height={'1px'}
                      width={'100%'}
                      background={theme.colors.whites[2]}
                    />
                  </NFTTitleContainer>
                  <FlexBox mt={'3px'}>
                    {min_sale_price && (
                      <Price priceTitle={'Lowest Ask'} minSalePrice={min_sale_price} />
                    )}
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </a>
          </Link>
        </NFTCardWrapper>
      </NFTCardBackground>
    </FlexBox>
  )
}

export default NFTCard
