import { FlexBox, TextField, theme, trackEvent, useInView } from '@rario/shared-components'
import { useCountdown } from 'hooks/useCountdown'
import { isFutureDate } from 'utils/utils'
import { FunctionComponent, useEffect, useState } from 'react'
import {
  PicksHeadingTextWrapper,
  PicksHeadingWrapper,
  TimerHeadingWrapper,
  TimerIndicator,
} from './Picks.styles'
import { PicksWidgetHeadingProps } from 'interfaces/picks'
import usePageControl from 'hooks/usePageControl'
import HeadingContent from 'components/HeadingContent/HeadingContent'
import { WidgetIdsEnum } from 'interfaces/homePage'

const PicksWidgetHeading: FunctionComponent<PicksWidgetHeadingProps> = ({ startTime, endTime }) => {
  const { isHomeLandingPage, isPickPlp } = usePageControl()
  const countDownTime = useCountdown(new Date(Number(endTime) * 1000))
  const saleEnded = countDownTime.isSaleEnded
  const isUpcomingSale = isFutureDate(new Date(Number(startTime) * 1000))
  const futureSaleTimeLeft = useCountdown(new Date(Number(startTime) * 1000))
  const picksHeadingText = saleEnded
    ? 'Sale Ended'
    : isUpcomingSale
    ? 'Goes Live In'
    : 'Sale Ends In'

  const picksTimerText = saleEnded
    ? ''
    : isUpcomingSale
    ? futureSaleTimeLeft.timeLeft
    : countDownTime.timeLeft

  const [element, setElement] = useState<HTMLElement | null | undefined>()
  const [inView] = useInView(element)

  useEffect(() => {
    /* track event on scroll */
    if (inView && isHomeLandingPage) {
      trackEvent('Section Viewed', 'track', {
        sub_category: 'homepage-picks_widget',
        category: 'picks',
        view_type: 'constrained',
        sale_ends_in: picksTimerText,
      })
    }
  }, [inView])

  return (
    <>
      <FlexBox
        width={'101%'}
        justifyContent={'flex-start'}
        height={'30px'}
        position="relative"
        ref={setElement}
        mb={'20px'}
      >
        <FlexBox
          width={'100%'}
          flexDirection={'row'}
          justifyContent={isPickPlp ? 'center' : 'flex-start'}
          minHeight={'50px'}
          zIndex={9}
        >
          {isHomeLandingPage ? (
            <FlexBox flexDirection={'row'} ml={'0px'} width={'100%'}>
              <TimerIndicator timerColor={countDownTime.timeColorVariant.color} />
              <FlexBox
                width={'92.5%'}
                flexDirection={'column'}
                position={'relative'}
                zIndex={9}
                mb={'20px'}
              >
                <FlexBox zIndex={999}>
                  <HeadingContent
                    heading={`Single Cards`}
                    subHeading={''}
                    markerLeftPosition={'-30px'}
                    widgetParentId={WidgetIdsEnum.PICKS_WIDGET}
                    category="homepage_pick_widget"
                  >
                    <FlexBox flexDirection={'column'}>
                      <TextField
                        fontSize={'12px'}
                        lineHeight={'16px'}
                        fontWeight={theme.fontWeights[4]}
                        fontFamily={theme.fonts.secondary}
                        pb={10}
                        content="What are Single Cards sold by Rario?"
                      />
                      <TextField
                        fontSize={'12px'}
                        lineHeight={'16px'}
                        fontWeight={theme.fontWeights[4]}
                        fontFamily={theme.fonts.secondary}
                        color={theme.colors.lightGrey}
                        pb={10}
                        content="Cherry-picked Player Cards sold straight from source, these high-tier gems could be the star performers of your D3 squad!"
                      />
                      <TextField
                        fontSize={'12px'}
                        lineHeight={'16px'}
                        fontWeight={theme.fontWeights[4]}
                        fontFamily={theme.fonts.secondary}
                        color={theme.colors.lightGrey}
                        pb={0}
                        content="Keep your eyes peeled for when they drop because the window closes quick!"
                      />
                    </FlexBox>
                  </HeadingContent>
                  <FlexBox
                    ml={'5px'}
                    px={'5px'}
                    width={'fit-content'}
                    height={'26px'}
                    background={theme.colors.whites[1]}
                    borderRadius={'100px'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <PicksHeadingWrapper
                      fontSize="12px"
                      lineHeight="16px"
                      mt={'3px'}
                      color={saleEnded ? theme.colors.white : theme.colors.tintedGray}
                      px="5px"
                    >
                      {picksHeadingText}
                      <TextField
                        fontSize={'12px'}
                        lineHeight="16px"
                        ml={'5px'}
                        content={picksTimerText}
                        color={countDownTime.timeColorVariant.borderVariant}
                      />
                    </PicksHeadingWrapper>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          ) : (
            <FlexBox
              px={'5px'}
              width={'fit-content'}
              height={'26px'}
              background={countDownTime.timeColorVariant.backgroundVariant}
              borderRadius={'100px'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <PicksHeadingWrapper
                mt={'5px'}
                px={'10px'}
                color={saleEnded ? theme.colors.white : theme.colors.tintedGray}
              >
                {picksHeadingText}
                <PicksHeadingTextWrapper
                  color={countDownTime.timeColorVariant.borderVariant}
                  content={picksTimerText}
                  ml={'5px'}
                />
              </PicksHeadingWrapper>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>

      {isPickPlp ? (
        <TimerHeadingWrapper
          background={
            isUpcomingSale
              ? theme.colors.primary
              : countDownTime.timeColorVariant.borderColorVariant
          }
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default PicksWidgetHeading
