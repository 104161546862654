import { FlexBox, FLexBoxProps, Input, TextField, theme } from '@rario/shared-components'
import CountDecreaseIcon from 'assets/svg/CountDecreaseIcon'
import CountIncreaseIcon from 'assets/svg/CountIncreaseIcon'
import { QuantityInputWrapper } from 'components/PackDetail/PackDetail.styles'
import { ChangeEvent, Dispatch, FunctionComponent } from 'react'
import { PackQuantityWrapper } from './PackBox.styles'

const PackQuantityBox: FunctionComponent<{
  qty: number
  setQty: Dispatch<React.SetStateAction<number>>
  maxQtyAllowed: number
  QuantityInputProps?: FLexBoxProps
  isPackBox?: boolean
  packName?: string
}> = ({ qty, setQty, maxQtyAllowed, QuantityInputProps, packName }) => {
  return (
    <PackQuantityWrapper flexDirection={'column'} width={'80px'} pl={'7px'} pr={'10px'}>
      <QuantityInputWrapper
        width={'100%'}
        height={'31px'}
        justifyContent={'center'}
        alignItems={'center'}
        {...QuantityInputProps}
        mt={'3px'}
      >
        <FlexBox
          onClick={(e) => {
            e.preventDefault()
            qty > 1 && setQty(qty - 1)
          }}
          cursor="pointer"
          height="100%"
          alignItems="center"
        >
          <CountDecreaseIcon
            width="20px"
            height="20px"
            fill={qty <= 1 ? theme.colors.whites[3] : theme.colors.secondary}
          />
        </FlexBox>

        <Input
          width={'25px !important'}
          name="pack_qty"
          customId={packName ? packName + qty : 'pack_qty'}
          variant="noBorder"
          fontSize={'12px'}
          fontWeight={400}
          type="number"
          value={qty}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setQty(Math.min(maxQtyAllowed, Math.floor(Number(event.target.value))))
          }
        />
        <FlexBox
          onClick={(e) => {
            e.preventDefault()
            qty < maxQtyAllowed && setQty(qty + 1)
          }}
          cursor="pointer"
          height="100%"
          alignItems="center"
        >
          <CountIncreaseIcon
            width="20px"
            height="20px"
            fill={qty >= maxQtyAllowed ? theme.colors.whites[3] : theme.colors.secondary}
          />
        </FlexBox>
      </QuantityInputWrapper>
      <FlexBox
        // mt={isPackBox ? '10px' : isPackPdp ? '2px' : qty < 1 ? '8px' : '40px'}
        // ml={'20px'}
        width={'100%'}
        flexDirection={'column'}
      >
        <TextField
          width={'100%'}
          textAlign={'center'}
          color={theme.colors.whites[7]}
          fontSize={'9px'}
          lineHeight={'9px'}
          fontWeight={400}
        >
          Max {maxQtyAllowed}
        </TextField>
        <TextField
          position={'relative'}
          top={'5px'}
          left={'-5px'}
          width={'80px'}
          lineHeight={'13px'}
          fontSize={'11px'}
          fontWeight={400}
          color={theme.colors.primary}
        >
          {qty < 1 && 'Use higher Qty'}
        </TextField>
      </FlexBox>
    </PackQuantityWrapper>
  )
}
export default PackQuantityBox
