import React, { FunctionComponent } from 'react'
import { TextField, theme } from '@rario/shared-components'
import { PackLaunchWrapper } from './PackBox.styles'
import { formatDates } from 'utils/utils'

const PackReserveTag: FunctionComponent<{
  reservation_start_ts?: number
  drop_start_ts?: number
  reservation_end_ts?: number
}> = ({ reservation_start_ts, drop_start_ts, reservation_end_ts }) => {
  const currentTimeStamp = new Date().getTime()
  const isReservationGoingOn =
    reservation_start_ts &&
    reservation_start_ts < currentTimeStamp &&
    reservation_end_ts &&
    reservation_end_ts > currentTimeStamp

  const isPastReservation =
    reservation_end_ts &&
    reservation_end_ts < currentTimeStamp &&
    drop_start_ts &&
    drop_start_ts > currentTimeStamp

  return (
    <PackLaunchWrapper
      flexDirection={'column'}
      justifyContent={'center'}
      mt={'5px'}
      width={'95px'}
      height={'40px'}
      borderRadius={'0px 5px 5px 0px'}
      pt={'5px'}
      pl={'5px'}
    >
      <TextField
        fontWeight={'400'}
        fontSize={'12px'}
        lineHeight={'14px'}
        color={theme.colors.mustard}
      >
        {isPastReservation || isReservationGoingOn ? 'Launches On' : 'Reserve On'}
      </TextField>
      <TextField fontWeight={'400'} fontSize={'10px'} lineHeight={'12px'}>
        {formatDates(
          isPastReservation || isReservationGoingOn ? drop_start_ts : reservation_start_ts,
          true,
          false,
          'monthShortName'
        )}
      </TextField>
    </PackLaunchWrapper>
  )
}

export default PackReserveTag
