import { FlexBox, TextField, theme, trackEvent } from '@rario/shared-components'
import { AppConfigContext } from 'contexts/AppConfigContext'
import usePageControl from 'hooks/usePageControl'
import { useRouter } from 'next/router'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { LeagueFilterWrapper } from './LeagueFilters.styles'
import { getLeagueFilters } from 'api/Algolia.api'

const LeagueFilters: FunctionComponent<{
  facetValueChangeCallback: Function
}> = ({ facetValueChangeCallback }) => {
  const router = useRouter()
  const { asPath } = router
  const { isHomeLandingPage } = usePageControl()
  const appConfig = useContext(AppConfigContext)
  const { leagueSorting } = appConfig
  const [sortedLeagueFacets, setSortedLeagueFacets] = useState<string[]>([])
  const [currentFacet, setCurrentFacet] = useState<string | null>(null)

  const getSortedPackLeagueFacets = async (): Promise<string[]> => {
    try {
      const data = await getLeagueFilters(leagueSorting)
      return data
    } catch (err) {
      // Silent catch
      return []
    }
  }

  useEffect(() => {
    getSortedPackLeagueFacets().then((res) => {
      setSortedLeagueFacets(res)
    })
  }, [router?.query])

  useEffect(() => {
    const defaultLeagueFacet = router?.query?.league as string
    const defaultLeague =
      defaultLeagueFacet && sortedLeagueFacets.includes(defaultLeagueFacet)
        ? defaultLeagueFacet
        : sortedLeagueFacets[0]
    setCurrentFacet(defaultLeague)
    defaultLeague && facetValueChangeCallback(defaultLeague)
  }, [sortedLeagueFacets])

  return (
    <FlexBox pl={isHomeLandingPage ? '20px' : 0} flexDirection={'column'}>
      {!isHomeLandingPage && (
        <TextField
          pt={10}
          fontWeight={'400'}
          fontSize={'14px'}
          lineHeight={'17.08px'}
          fontFamily={theme.fonts.secondary}
          color={theme.colors.whites[8]}
        >
          Filter by Leagues
        </TextField>
      )}
      {sortedLeagueFacets.length > 0 && (
        <LeagueFilterWrapper width={'100%'} height="min-content" mt={'10px'} overflowX={'auto'}>
          {sortedLeagueFacets.map((item, index) => {
            return (
              <FlexBox
                key={`league-sorting-${index}`}
                border={`1px solid ${theme.colors.whites[5]}`}
                borderRadius="20px"
                cursor="pointer"
                background={currentFacet === item ? `${theme.colors.whites[8]}` : `transparent`}
                mr="10px"
                onClick={() => {
                  if (item === currentFacet) {
                    return
                  }
                  setCurrentFacet(item)
                  facetValueChangeCallback(item)
                  trackEvent('Section Interacted', 'click', {
                    sub_category: 'packs-league_filter',
                    type: 'list',
                    category: 'packs',
                    view_type: 'constrained',
                    source_screen: asPath,
                    click_text: item,
                    click_type: 'button',
                    click_action: 'apply_league_filter_packs',
                  })
                }}
              >
                <TextField
                  color={currentFacet === item ? theme.colors.black : theme.colors.white}
                  whiteSpace="pre"
                  p={'8px 13px'}
                  fontSize="12px"
                  lineHeight={'16px'}
                >
                  {item}
                </TextField>
              </FlexBox>
            )
          })}
        </LeagueFilterWrapper>
      )}
    </FlexBox>
  )
}
export default LeagueFilters
