import { FunctionComponent } from 'react'
import { FlexBox, Image, TextField, theme } from '@rario/shared-components'
import { getCDNUrl } from 'utils/utils'

const PackListEmptyScreen: FunctionComponent = () => {
  return (
    <FlexBox
      flexDirection="column"
      justifyContent={'center'}
      alignItems={'center'}
      mt={'50px'}
      mb={['55px', null, null, '25px']}
      width="100%"
    >
      <Image
        width={'200px'}
        height="200px"
        src={getCDNUrl('/App/images/no-result/cricket-kit.png')}
      />

      <TextField
        fontFamily={theme.fonts.primary}
        letterSpacing={theme.letterSpacings.primary}
        fontWeight={700}
        fontSize="16px"
        lineHeight={'22px'}
        textAlign={'center'}
        pb={0}
        mt={'42px'}
        mb={'35px'}
      >
        It's empty here
      </TextField>

      <TextField
        fontWeight={400}
        fontSize={'12px'}
        lineHeight={'16px'}
        textAlign={'center'}
        opacity={0.8}
        pb={0}
      >
        Perhaps, be back later?
      </TextField>
    </FlexBox>
  )
}

export default PackListEmptyScreen
