import { Image } from '@rario/shared-components'
import { getCDNUrl } from 'utils/utils'
import { NFTCardTagWrapper } from './NFTCard.styles'

const NFTCardTag: React.FunctionComponent<{ cardTag?: string; scarcity?: string }> = ({
  cardTag,
  scarcity,
}) => {
  return (
    <NFTCardTagWrapper cardTag={cardTag}>
      <Image
        width={'36px'}
        height={'112px'}
        src={getCDNUrl('/App/images/marketplace/' + scarcity + '-' + cardTag + '-v1.webp')}
        name={scarcity}
      />
    </NFTCardTagWrapper>
  )
}

export default NFTCardTag
