import { TextField, FlexBox, customStyled, ThemeType, theme } from '@rario/shared-components'
import { PaddingProps, padding } from 'styled-system'

export const NFTCardWrapper = customStyled.div<
  {
    theme?: ThemeType
    isHome?: boolean
    cardsCount?: number
    isHomeLandingPage?: boolean
  } & PaddingProps
>`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  ${padding};
  align-items: center;
  width: ${({ isHomeLandingPage }) => (isHomeLandingPage ? '160px' : '170px')};
  height: 305px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
    overflow: hidden;
  }
  background: linear-gradient(225deg, ${({ theme }) => theme.colors.whites[4]} 19px, #101318 19px);
  box-shadow: inset 20.1667px -20.1667px 20.1667px rgba(13, 17, 21, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 5px;
  border: 0.5px solid ${({ theme }) => theme.colors.whites[4]};
  @media (max-width: 380px) {
    width: ${({ isHomeLandingPage }) => (isHomeLandingPage ? '155px' : '160px')};
  }
  
  ${({ cardsCount }) => `
    @media(min-width: ${theme.breakpoints[2]}) { 
      &:nth-of-type(${cardsCount + 'n+' + cardsCount}) div.tooltiptext {
        left: unset;
        right: 32px;
  
        &::after{
          left: unset;
          right: -24px;
          border-color: transparent transparent transparent rgba(22, 51, 46, 0.85);
        }
      }
    }
  `}
`

export const NFTCardBackground = customStyled(FlexBox)<{ theme?: ThemeType }>`
    border-radius: 4px;
    mask-image: linear-gradient(225deg, transparent 19px, white 19px);
`

export const NFTTitleContainer = customStyled(FlexBox)<{ pt?: any; width?: string }>`
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:   ${({ width = '150px' }) => width};
  color: rgb(255, 255, 255);
  z-index: 10;
  display:block;
  padding-top: ${({ pt = 0 }) => pt};
  @media (max-width: 768px) {
    width: 150px;
    height:auto;
    display:block;
    padding-top: ${({ pt = 0 }) => pt};
  }
  @media (max-width: 360px) {
    width: 154px;
  }
`
export const NFTSubtitle = customStyled(TextField)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  @media (max-width: ${theme.breakpoints[1]}) {
    width: 150px;
  }
`

export const NFTCardTagWrapper = customStyled(FlexBox)<{ cardTag?: string }>`
  position: absolute;
  top: 10%;
  left: -3%;
  align-self: center;
  z-index: 9999999;
  @media(max-width: ${theme.breakpoints[1]}) {
      left: -3%;
  }
`
