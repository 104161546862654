const CountDecreaseIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '18', height = '17', fill = 'none' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 17"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 7.66668C5.20643 7.66668 4.83333 8.03978 4.83333 8.50002C4.83333 8.96025 5.20643 9.33335 5.66667 9.33335V7.66668ZM12.3333 9.33335C12.7936 9.33335 13.1667 8.96025 13.1667 8.50002C13.1667 8.03978 12.7936 7.66668 12.3333 7.66668V9.33335ZM9 15.1667C5.3181 15.1667 2.33333 12.1819 2.33333 8.50002H0.666667C0.666667 13.1024 4.39763 16.8333 9 16.8333V15.1667ZM2.33333 8.50002C2.33333 4.81812 5.3181 1.83335 9 1.83335V0.166682C4.39763 0.166682 0.666667 3.89764 0.666667 8.50002H2.33333ZM9 1.83335C12.6819 1.83335 15.6667 4.81812 15.6667 8.50002H17.3333C17.3333 3.89764 13.6024 0.166682 9 0.166682V1.83335ZM15.6667 8.50002C15.6667 12.1819 12.6819 15.1667 9 15.1667V16.8333C13.6024 16.8333 17.3333 13.1024 17.3333 8.50002H15.6667ZM5.66667 9.33335H12.3333V7.66668H5.66667V9.33335Z"
      fill={fill}
    />
  </svg>
)

export default CountDecreaseIcon
