import { FlexBox, Image, TextField, theme, trackEvent } from '@rario/shared-components'
import {
  BuyPacksCarousel,
  PackBackgroundImageContainer,
  PackBoxContainer,
  PackIconWrapper,
  PackSubTitleContainer,
  PackTitleContainer,
} from './PackBox.styles'
import usePackCardHook from 'hooks/usePackCardHook'
import { PacksDoc } from 'interfaces'
import { useCallback, useContext, useState } from 'react'
import { getCDNUrl, isFutureDate, packSubtitle } from 'utils/utils'
import PackPurchaseButton from 'components/PackDetail/PackPurchaseButton'
import Link from 'next/link'

import dynamic from 'next/dynamic'
import PackSummary from 'components/PackSummary/PackSummary'
import usePageControl from 'hooks/usePageControl'
import { ModalContext } from 'components/ModalManager/ModalProvider'
import { appendHomeAsReferer } from 'utils/urlUtils'
import ReservedTag from 'assets/svg/ReservedTag'
import PackQuantityBox from './PackQuantity'
import PackSoldOutIcon from 'assets/svg/PackSoldOutIcon'
import InfoIcon from 'assets/svg/InfoIcon'
import CrossIcon from 'assets/svg/CrossIcon'
import PackReserveTag from './PackReserveTag'
import useContentfulUrl from 'hooks/useContentfulUrl'
import { PLATFORM_ORIGIN } from 'config'
import CricrushPackSoldOutIcon from 'assets/svg/CricRushPackSoldOutIcon'

const PackPriceInfo = dynamic(import('./PackPriceInfo'))

const PackBox: React.FunctionComponent<{
  packsDoc: PacksDoc
  appendRefererInLink?: boolean
  currentFacetValue?: string | null
}> = ({ packsDoc, currentFacetValue, appendRefererInLink = false }) => {
  const {
    name,
    image,
    sale_price,
    pack_summary,
    asset_id,
    drop_start_ts,
    reservation_start_ts,
    is_sold_out,
    drop_end_ts,
    reservation_end_ts,
    max_qty_allowed_per_order,
    pack_config,
    list_of_moments_cms_ref_id,
    item_count,
    player_pool_count,
  } = packsDoc

  const imageSource = useContentfulUrl(image, {
    format: 'webp',
    width: 150,
    height: 150,
  })

  const [packState] = usePackCardHook(
    reservation_start_ts,
    drop_start_ts,
    is_sold_out,
    drop_end_ts,
    reservation_end_ts,
    sale_price
  )
  const { isHomeLandingPage, isPackPdp } = usePageControl()

  const { ctaText } = packState
  const PackSubTitle = packSubtitle(pack_config, false, item_count)
  const packEventData = {
    type: 'list',
    category: 'packs',
    view_type: 'constrained',
    pack_name: name,
    pack_id: asset_id,
    pack_price: sale_price,
    pack_config,
  }

  const trackPacks = (clickAction?: string) => {
    const click_action =
      clickAction || (isPackPdp ? `click_packs_recommendation_widget` : `click_pack_card`)
    const packRecommendedEventData = {
      pack_id: asset_id,
      pack_name: name,
      pack_price: sale_price,
    }
    const eventData = isPackPdp
      ? packRecommendedEventData
      : { ...packEventData, pack_quantity: qty, click_text: ctaText, click_type: 'card' }

    trackEvent('Section Interacted', 'click', {
      ...eventData,
      click_action,
      sub_category: 'packs-widget',
    })
  }

  const currentTimeStamp = new Date().getTime()
  const dropEnded = reservation_end_ts < currentTimeStamp && drop_end_ts < currentTimeStamp

  const [purchaseMessageText, setPurchaseMessageText] = useState('')
  const [isReserved, setIsReserved] = useState<boolean>(false)
  const [isEligible, setIsEligible] = useState(false)
  const purchaseButtonCallBack = useCallback(
    (message: string, isPackReserved: boolean) => {
      if (message) {
        setPurchaseMessageText(message)
        setIsEligible(!!message)
      }
      setIsReserved(isPackReserved)
    },
    [purchaseMessageText]
  )

  const isReservationInFuture = isFutureDate(reservation_start_ts)

  const isDropInFuture = isFutureDate(drop_start_ts)

  const [showFront, setShowFront] = useState(true)

  const packsAssetIdpath = appendHomeAsReferer(
    `/packs/${asset_id}${currentFacetValue && `?league=${currentFacetValue}`}`,
    appendRefererInLink
  )

  const [qty, setQty] = useState(1)
  // const [showQty, setShowQty] = useState<boolean>(false)

  const { setPlayerPoolModal } = useContext(ModalContext)

  const bottomMargin = isPackPdp ? '40px' : '30px'

  const flipButton = (
    <FlexBox
      borderBottomLeftRadius={'5px'}
      zIndex={9999999}
      background={'rgba(51, 51, 51, 1)'}
      width={'32px'}
      height={'32px'}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        setShowFront(!showFront)
        trackPacks('click_packbox_flip_toggle')
      }}
      position={'absolute'}
      right={'0px'}
      top={'0px'}
    >
      {showFront ? (
        <FlexBox position={'absolute'} top={'9px'} right={'9px'}>
          <InfoIcon width="15px" height="15px" />
        </FlexBox>
      ) : (
        <FlexBox position={'absolute'} top={'12px'} right={'12px'}>
          <CrossIcon width="13px" height="13px" />
        </FlexBox>
      )}
    </FlexBox>
  )

  const trackPlayerPoolModal = () => {
    trackEvent('Section Interacted', 'click', {
      ...packEventData,
      click_type: 'link',
      click_text: 'See Player Pool',
      sub_category: 'pack-player_pool',
      click_action: 'show_player_pool',
    })
  }

  return (
    <FlexBox flexDirection={'column'}>
      <PackBoxContainer>
        <BuyPacksCarousel
          mb={bottomMargin}
          flexDirection={'row'}
          alignItems={'center'}
          cursor={'pointer'}
          zIndex={9}
          className={'scene'}
          isHomeLandingPage={isHomeLandingPage}
        >
          <FlexBox width={'100%'} height={'100%'} className={showFront ? 'card' : 'card isFlipped'}>
            <FlexBox
              width={'100%'}
              height={'100%'}
              className={'frontFace'}
              position={'absolute'}
              top={'0px'}
              left={'0px'}
              onClick={() => trackPacks()}
            >
              {isReserved && (
                <PackIconWrapper>
                  <ReservedTag />
                </PackIconWrapper>
              )}
              {(is_sold_out || dropEnded) && (
                <PackIconWrapper>
                  {PLATFORM_ORIGIN === 'CR' ? <CricrushPackSoldOutIcon /> : <PackSoldOutIcon />}
                </PackIconWrapper>
              )}
              <Link style={{ zIndex: 9999 }} href={packsAssetIdpath} key={asset_id} passHref>
                <FlexBox flexDirection={'column'} width="29%">
                  <PackBackgroundImageContainer
                    background={`url(${getCDNUrl(
                      '/App/images/user-profile/vault-pack-bg-v2.png'
                    )})`}
                    backgroundPosition={'center'}
                    backgroundSize={'cover'}
                    backgroundRepeat={'no-repeat'}
                    mx={'10px'}
                    mt={'10px'}
                    mb={'5px'}
                  >
                    <FlexBox
                      width={'100%'}
                      height={'100%'}
                      alignSelf={'flex-start'}
                      flexDirection={'column'}
                      alignItems="center"
                      justifyContent={'space-around'}
                      overflowX={'hidden'}
                    >
                      <Image width={'85px'} height={'85px'} src={imageSource} name={name} />
                    </FlexBox>
                  </PackBackgroundImageContainer>
                  {(isFutureDate(drop_start_ts) || isFutureDate(reservation_start_ts)) && (
                    <PackReserveTag
                      reservation_start_ts={reservation_start_ts}
                      reservation_end_ts={reservation_end_ts}
                      drop_start_ts={drop_start_ts}
                    />
                  )}{' '}
                  {!(
                    is_sold_out ||
                    dropEnded ||
                    isReservationInFuture ||
                    isDropInFuture ||
                    isEligible
                  ) && (
                    <FlexBox
                      ml={'10px'}
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                      }}
                    >
                      <PackQuantityBox
                        maxQtyAllowed={max_qty_allowed_per_order}
                        qty={qty}
                        setQty={setQty}
                        isPackBox
                        packName={name}
                      />
                    </FlexBox>
                  )}
                </FlexBox>
              </Link>
              <FlexBox
                flexDirection={'column'}
                mx={'10px'}
                width={'205px'}
                height={'100%'}
                mt={'5px'}
              >
                <FlexBox flexDirection={'column'} zIndex={9999} onClick={() => trackPacks()}>
                  <Link style={{ zIndex: 9999 }} href={packsAssetIdpath} key={asset_id} passHref>
                    <FlexBox
                      mt={'8px'}
                      onClick={() => {
                        window?.ReactNativeWebView?.postMessage('custom_click')
                      }}
                    >
                      <PackTitleContainer
                        truncate
                        fontFamily={theme.fonts.primary}
                        letterSpacing={theme.letterSpacings.primary}
                        fontWeight={theme.fontWeights[5]}
                        fontSize={'18px'}
                        lineHeight={'24px'}
                        width={'90%'}
                      >
                        <label htmlFor={name ? name + qty : 'pack_qty'}>{name}</label>
                      </PackTitleContainer>
                    </FlexBox>
                  </Link>
                  <FlexBox flexDirection={'column'}>
                    <PackSubTitleContainer
                      truncate
                      color={theme.colors.whites[7]}
                      fontWeight={theme.fontWeights[2]}
                      fontSize={'12px'}
                      lineHeight={'16px'}
                      width={'90%'}
                      textTransform={'capitalize'}
                    >
                      Get {PackSubTitle}
                    </PackSubTitleContainer>
                    {player_pool_count ? (
                      <PackSubTitleContainer
                        color={theme.colors.whites[7]}
                        fontWeight={theme.fontWeights[2]}
                        fontSize={'12px'}
                        lineHeight={'16px'}
                        width={'170px'}
                        textTransform={'capitalize'}
                      >
                        Out Of{' '}
                        {list_of_moments_cms_ref_id && (
                          <TextField
                            onClick={() => {
                              setPlayerPoolModal({
                                cmsRefId: list_of_moments_cms_ref_id,
                                source: 'packs',
                              })
                              trackPlayerPoolModal()
                            }}
                            fontWeight={400}
                            fontSize={'12px'}
                            lineHeight={'16px'}
                            color={theme.colors.secondary}
                            content={`${player_pool_count} Player Cards`}
                          />
                        )}
                      </PackSubTitleContainer>
                    ) : (
                      <></>
                    )}
                  </FlexBox>
                </FlexBox>
                <FlexBox
                  height={'1px'}
                  width={'100%'}
                  background={theme.colors.whites[4]}
                  mt={player_pool_count ? '5px' : '10px'}
                />
                <FlexBox flexDirection={'column'}>
                  <FlexBox flexDirection={'row'} mt={player_pool_count ? '5px' : '10px'}>
                    <PackPriceInfo salePrice={sale_price} />
                  </FlexBox>
                  {purchaseMessageText ? (
                    <FlexBox
                      flexDirection={'column'}
                      height={'40px'}
                      width={'100%'}
                      mt={'10px'}
                      position={'relative'}
                    >
                      <TextField fontWeight={400} fontSize={'12px'} lineHeight={'14px'}>
                        {purchaseMessageText}
                      </TextField>
                    </FlexBox>
                  ) : (
                    <FlexBox mt={player_pool_count ? '5px' : '10px'} ml={'0px'}>
                      <PackPurchaseButton
                        packsDoc={packsDoc}
                        packState={packState}
                        orderQty={qty}
                        purchaseButtonCallBack={purchaseButtonCallBack}
                      />
                    </FlexBox>
                  )}
                </FlexBox>
              </FlexBox>
              {flipButton}
            </FlexBox>
            <FlexBox
              position={'absolute'}
              top={'0px'}
              left={'0px'}
              height={'100%'}
              width={'100%'}
              className="backFace"
              flexDirection={'column'}
              py={'10px'}
            >
              <FlexBox>
                <PackTitleContainer
                  truncate
                  fontFamily={theme.fonts.primary}
                  letterSpacing={theme.letterSpacings.primary}
                  fontWeight={theme.fontWeights[5]}
                  fontSize={'14px'}
                  lineHeight={'20px'}
                  width={'100%'}
                  pb={'10px'}
                  pl={'10px'}
                >
                  {name}
                </PackTitleContainer>
              </FlexBox>
              <FlexBox mt={'5px'} mb={'11px'} overflow={'auto'} px={'15px'}>
                <PackSummary packSummary={pack_summary} />
              </FlexBox>
              {flipButton}
            </FlexBox>
          </FlexBox>
        </BuyPacksCarousel>
      </PackBoxContainer>
    </FlexBox>
  )
}

export default PackBox
