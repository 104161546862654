import { FlexBox, Image, TextField, theme } from '@rario/shared-components'
import { BuyPacksBannerWrapper, PackTitleContainer } from './PackBox.styles'
import { getCDNUrl } from 'utils/utils'
import usePageControl from 'hooks/usePageControl'

const buyPackBannerData = [
  {
    index: 1,
    heading: 'Use 3 cards to create team on D3',
  },
  {
    index: 2,
    heading: 'Use the same cards in multiple matches',
  },
]
const BuyPackBanner: React.FunctionComponent = () => {
  const { isHomeLandingPage } = usePageControl()
  return (
    <BuyPacksBannerWrapper
      background={
        isHomeLandingPage
          ? `url(${getCDNUrl('/App/images/home/buy-pack-banner-bg-v3.png')})`
          : `url(${getCDNUrl('/App/images/home/buy-pack-banner-bg-v2s.png')})`
      }
      backgroundPosition={'center'}
      backgroundSize={'cover'}
      backgroundRepeat={'no-repeat'}
      border={isHomeLandingPage ? '0px' : `0.5px solid ${theme.colors.whites[4]}`}
      mt={'40px'}
    >
      <FlexBox flexDirection={'row'} width={'100%'}>
        <FlexBox
          width={'30%'}
          height={'100%'}
          alignSelf={'flex-start'}
          flexDirection={'column'}
          alignItems="center"
          justifyContent={'space-around'}
          overflowX={'hidden'}
        >
          <Image
            width={'95px'}
            height={'95px'}
            src={getCDNUrl('/App/images/home/buy-pack-banner.webp', true)}
            name={'buy-pack-banner'}
          />
        </FlexBox>
        <FlexBox mt={'12px'} width={'70%'} height={'100%'} flexDirection={'column'}>
          <PackTitleContainer
            fontFamily={theme.fonts.primary}
            letterSpacing={theme.letterSpacings.primary}
            fontWeight={700}
            fontSize={'18px'}
            lineHeight={'24px'}
            width={'100%'}
          >
            WHY BUY CARDS?
          </PackTitleContainer>
          {buyPackBannerData.map((item, index) => (
            <FlexBox
              mt={'5px'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'center'}
              key={index}
            >
              <FlexBox
                width={'3px'}
                height={'3px'}
                borderRadius={'10px'}
                background={theme.colors.white}
              />
              <TextField
                pb={'0px'}
                ml={'5px'}
                fontFamily={theme.fonts.primary}
                letterSpacing={theme.letterSpacings.primary}
                fontWeight={400}
                fontSize={'12px'}
                lineHeight={'14px'}
                width={'100%'}
              >
                {item.heading}
              </TextField>
            </FlexBox>
          ))}
        </FlexBox>
      </FlexBox>
    </BuyPacksBannerWrapper>
  )
}

export default BuyPackBanner
