import { WalletBalanceProps } from 'interfaces'
import { createContext } from 'react'

export const UserWalletContext = createContext<{
  walletBalance: WalletBalanceProps | undefined
  isWalletBalanceLoading: boolean
}>({
  walletBalance: undefined,
  isWalletBalanceLoading: false,
})
