type imageFormat = 'jpg' | 'png' | 'webp' | 'gif' | 'avif'
type resizeParams = 'pad' | 'fill' | 'scale' | 'crop' | 'thumb'

const useContentfulUrl = (
  imageUrl: string,
  options: {
    format?: imageFormat
    width: number
    height: number
    //   quality can only accept whole numbers from 1 to 100
    quality?: number
    fit?: resizeParams
  }
) => {
  const { format = 'webp', width, height, quality = 100, fit } = options

  const url = `${imageUrl}?fm=${format}&w=${width}&h=${height}&q=${quality}${
    fit ? `&fit=${fit}` : ''
  }`

  return url
}

export default useContentfulUrl
