import { Hit } from 'react-instantsearch-core'
import { FlexBox, TextField, theme, trackEvent } from '@rario/shared-components'
import NFTDetailSingleImage from 'components/NFTDetail/NFTDetailSingleImage'
import Link from 'next/link'
import {
  getDiscountedPrice,
  getCDNUrl,
  convertToInternationalCurrencySystem,
  getCurrentYear,
} from 'utils/utils'
import { D3CatBoostEnum, NFTTagProps, NFTTokenDoc } from 'interfaces'
import {
  NFTCardBottom,
  NFTCardWrapper,
  NFTTitleContainer,
  VaultNFTBackgroundContainer,
} from 'components/VaultNFTCard/VaultNFTCard.styles'
import SoldByRarioTag from 'components/NFTDetail/SoldByRarioTag'
import NFTCardRoleTag from 'components/VaultNFTCard/NFTCardRoleTag'
import NFTPicksButton from 'components/Picks/NFTPicksButton'
import usePageControl from 'hooks/usePageControl'
import { appendHomeAsReferer } from 'utils/urlUtils'
import { cardScarcityBackground } from 'interfaces/nftDetail'
import NFTCardTag from 'components/NFTCard/NFTCardTag'
import useCurrencyConversion from 'hooks/useCurrencyConversion'
import { useContext } from 'react'
import { UserWalletContext } from 'contexts/UserWalletContext'

const PicksNFTCard: React.FunctionComponent<{
  hit: Hit<NFTTokenDoc>
  appendRefererInLink?: boolean
}> = ({ hit, appendRefererInLink = false }) => {
  const {
    objectID: catalogId,
    attributes,
    image,
    sale_price,
    name,
    batting_position,
    bowling_position,
    is_star_player,
    campaign_end_ts,
    sub_type,
  } = hit
  const { walletBalance } = useContext(UserWalletContext)
  const { role, team, league, player, tournament, scarcity, year } = attributes
  const listingUrl = appendHomeAsReferer(`/listings/picks/${catalogId}`, appendRefererInLink)

  const trackNFTCard = () => {
    trackEvent('Section Interacted', 'click', {
      sub_category: 'picks-listing',
      type: 'list',
      category: 'picks',
      view_type: 'paginated',
      pick_id: catalogId,
      pick_name: name,
      pick_price: sale_price,
      sale_end_datetime: campaign_end_ts,
      click_type: 'card',
      click_action: 'picks_listing_click',
    })
  }

  const { isHomeLandingPage } = usePageControl()

  const tagProps: NFTTagProps[] = [{ role, batting_position, bowling_position }]

  if (Number(year) === getCurrentYear() && scarcity.toUpperCase() !== D3CatBoostEnum.BLUE) {
    tagProps.push({ tagText: 'NEW' })
  }

  const marketingWalletBalance = walletBalance?.marketingWalletBalance || 0

  const priceAfterDiscount = getDiscountedPrice(sale_price, marketingWalletBalance)
  const [, currency, , localizedPriceAfterDiscount] = useCurrencyConversion(priceAfterDiscount)
  const [formattedSalePrice, , convertedPrice] = useCurrencyConversion(sale_price)

  return (
    <FlexBox flexDirection={'column'}>
      {role && <NFTCardRoleTag tagProps={tagProps} />}
      <FlexBox mb={'30px'}>
        <FlexBox flexDirection={'column'}>
          <NFTCardWrapper
            onClick={() => {
              trackNFTCard()
            }}
            isPick={true}
            isHomeLandingPage={isHomeLandingPage}
          >
            <Link href={listingUrl} passHref>
              <a>
                {is_star_player && <NFTCardTag cardTag={sub_type} scarcity={scarcity} />}
                <VaultNFTBackgroundContainer
                  width={'90%'}
                  margin={'auto'}
                  backgroundImage={
                    is_star_player
                      ? `url(${cardScarcityBackground[scarcity]})`
                      : `url(${getCDNUrl('/App/images/user-profile/vault-pack-bg-v2.png')})`
                  }
                  backgroundSize={'cover'}
                  backgroundPosition={'center'}
                  backgroundRepeat={'no-repeat'}
                  borderRadius={'8px'}
                  mt={'10px'}
                >
                  <FlexBox
                    position="relative"
                    flexDirection="column"
                    width={'100%'}
                    onClick={() => {
                      window?.ReactNativeWebView?.postMessage('custom_click')
                    }}
                  >
                    <NFTDetailSingleImage
                      imageUrl={image}
                      height={'176px'}
                      width={'110px'}
                      margin={'auto'}
                      cursor="pointer"
                      alt={player}
                      scarcity={scarcity}
                      is_star_player={is_star_player}
                    />
                    <SoldByRarioTag isListing={false} />
                  </FlexBox>
                </VaultNFTBackgroundContainer>
                <FlexBox flexDirection="column" justifySelf="center" px={'7px'}>
                  <NFTTitleContainer pt={'5px'}>
                    <TextField
                      fontSize={'18px'}
                      lineHeight={'22px'}
                      fontFamily={theme.fonts.primary}
                      letterSpacing={theme.letterSpacings.primary}
                      fontWeight={600}
                      pb={0}
                      as="span"
                    >
                      {player}
                    </TextField>
                  </NFTTitleContainer>
                  <FlexBox flexDirection="column" height={'80px'}>
                    <NFTTitleContainer pt="5px">
                      <TextField
                        fontSize="12px"
                        lineHeight="16px"
                        fontWeight={400}
                        as="span"
                        color={theme.colors.whites[9]}
                      >
                        {`${team},${' '}${league} ${tournament}`}
                      </TextField>
                    </NFTTitleContainer>
                    <NFTCardBottom flexDirection={'column'} mt={'10px'}>
                      {walletBalance?.marketingWalletBalance ? (
                        <>
                          <FlexBox pt={'12px'} alignItems="baseline">
                            <TextField
                              fontFamily={theme.fonts.primary}
                              letterSpacing={theme.letterSpacings.primary}
                              fontWeight={600}
                              fontSize={'14px'}
                              lineHeight={'22px'}
                            >
                              {currency}
                            </TextField>
                            <TextField
                              fontFamily={theme.fonts.primary}
                              letterSpacing={theme.letterSpacings.primary}
                              fontWeight={600}
                              fontSize={'20px'}
                              lineHeight={'28px'}
                            >
                              {localizedPriceAfterDiscount}
                            </TextField>
                          </FlexBox>
                          <TextField
                            fontSize={'16px'}
                            lineHeight={'18px'}
                            fontWeight={6}
                            color={theme.colors.grey}
                            fontFamily={theme.fonts.primary}
                            letterSpacing={theme.letterSpacings.primary}
                            textDecoration={'line-through'}
                          >
                            {formattedSalePrice}
                          </TextField>
                        </>
                      ) : (
                        <FlexBox pt={'12px'} alignItems="baseline">
                          <TextField
                            fontFamily={theme.fonts.primary}
                            letterSpacing={theme.letterSpacings.primary}
                            fontWeight={600}
                            fontSize={'14px'}
                            lineHeight={'22px'}
                          >
                            {currency}
                          </TextField>
                          <TextField
                            fontFamily={theme.fonts.primary}
                            letterSpacing={theme.letterSpacings.primary}
                            fontWeight={600}
                            fontSize={'20px'}
                            lineHeight={'28px'}
                          >
                            {convertToInternationalCurrencySystem(Number(convertedPrice))}
                          </TextField>
                        </FlexBox>
                      )}
                    </NFTCardBottom>
                    <FlexBox
                      bottom={'10px'}
                      right={'15px'}
                      position={'absolute'}
                      onClick={(e) => {
                        e.preventDefault()
                      }}
                    >
                      <NFTPicksButton catalogDoc={hit} walletBalance={walletBalance} />
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </a>
            </Link>
          </NFTCardWrapper>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default PicksNFTCard
