import { doGet } from 'api'
import API_CONSTANTS from './constants'
import QueryString from 'query-string'
import { UserCohorts } from 'interfaces'

export const getMarketplaceWidgetData = (league: string[]) => {
  const uri = API_CONSTANTS.GET_MARKETPLACE_WIDGET + '?' + QueryString.stringify({ league })
  return doGet(
    uri,
    { isAbsUrl: true },
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  )
}

export const getPicksWidgetData = () => {
  return doGet(
    API_CONSTANTS.GET_PICKS_WIDGET,
    { isAbsUrl: true },
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  )
}

export const getPacksWidgetData = (league: string | null, userCohort: UserCohorts) => {
  return doGet(
    API_CONSTANTS.GET_PACKS_WIDGET,
    { isAbsUrl: true, userCohort, league },
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  )
}

export const getLeagueFilters = (leagueSorting: string[]) => {
  const uri = API_CONSTANTS.GET_LEAGUE_FILTERS + '?' + QueryString.stringify({ leagueSorting })
  return doGet(
    uri,
    { isAbsUrl: true },
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  )
}
