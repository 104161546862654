import { FlexBox, TextField, theme } from '@rario/shared-components'
import { PackSummaryProps } from 'interfaces'

const PackSummary: React.FunctionComponent<PackSummaryProps> = ({ packSummary }) => {
  return (
    <FlexBox flexDirection={'column'} px={'5px'}>
      {packSummary &&
        packSummary.map((item: string, index: number) => (
          <FlexBox flexDirection={'row'} key={index}>
            <FlexBox height={'15px'} pt={'5px'}>
              <FlexBox
                width={'3px'}
                height={'3px'}
                borderRadius={'10px'}
                background={theme.colors.whites[6]}
              />
            </FlexBox>
            <TextField
              ml={'15px'}
              color={theme.colors.whites[6]}
              fontWeight={theme.fontWeights[3]}
              fontSize={'12px'}
              lineHeight={'14px'}
              content={item}
            />
          </FlexBox>
        ))}
    </FlexBox>
  )
}

export default PackSummary
