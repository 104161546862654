import { ReactNode } from 'react'
import { UserWalletContext } from './UserWalletContext'
import useUserWallet from 'hooks/useUserWallet'

const UserWalletProvider = ({ children }: { children: ReactNode }) => {
  const value = useUserWallet()
  return <UserWalletContext.Provider value={value}>{children}</UserWalletContext.Provider>
}

export default UserWalletProvider
